<template>
  <branch-select
    v-if="branchList?.length > 1"
    :disabled="readOnly"
    :label="$t('branch')"
    :value="obInvoice.branch_id"
    :wrap-options="{ cols: 12, md: 6 }"
    class="col-12 col-md-6 pt-7 pl-6 pb-0 mb-0"
    select
    tag="div"
    @change="onSelectBranch"
  />
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import CompanyPreview from "@/modules/companies/components/CompanyPreview.vue";
import BranchSelect from "@/modules/companies/components/BranchSelect.vue";
import type { Branch, BranchData } from "@planetadeleste/vue-mc-gw";
import { AuthModule } from "@/store/auth";
import { filter, get } from "lodash";

@Component({
  components: { CompanyPreview, BranchSelect },
})
export default class InvoiceFormTop extends Mixins(InvoiceMixin) {
  @Prop(Boolean) readonly readOnly!: boolean;

  get user() {
    return AuthModule.user;
  }

  get isAdmin() {
    return AuthModule.isAdmin;
  }

  get branchList(): BranchData[] {
    if (this.isAdmin) {
      return this.company?.branches ?? [];
    }

    const availableBranches: number[] = get(this.user, "branch_list_id", []);
    const _branches = this.company?.branches ?? [];

    return filter(_branches, (obBranch) => {
      return availableBranches.includes(obBranch.id);
    });
  }

  onSelectBranch(obData: Branch) {
    this.obInvoice.set("branch_id", obData.id);
  }
}
</script>
